<template>
  <div>
    <template v-if="!loading.overall">
      <v-container>
        <SettingsGear :showIcon="true" />
        <div style="height: 15vh">
          <div
            style="
              display: flex;
              height: 100%;
              align-items: center;
              justify-content: center;
              margin-top: 2vh;
            "
          >
            <img src="@/assets/logos/dime_logo_gold.png" style="height: 7em" />
          </div>
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-content: flex-end;
            "
          >
            <!-- <img
              src="@/assets/logos/dime_thinkhigher.png"
              style="height: 2.5em"
            /> -->
          </div>
        </div>
        <div style="height: 60vh">
          <div style="margin-top: 1vh"></div>
          <div class="row">
            <!-- One Device -->
            <div class="col col-6">
              <div class="device">
                <img class="device-background" src="@/assets/ui/device.png" />
                <img
                  @touchstart="toggleFireStart"
                  @touchend="toggleFireStop"
                  @click="togglePreheat"
                  class="device-logo"
                  :src="
                    isConnected ? fireImage.connected : fireImage.disconnected
                  "
                />
                <div class="device-title" v-show="isConnected">
                  <img
                    class="device-title-logo"
                    src="@/assets/ui/device_logo_small.png"
                  />
                  <img class="device-title-electricity" :src="batteryIcon" />
                </div>
                <span
                  v-show="isConnected && !isPreheatMode"
                  class="device-temperature"
                >
                  {{
                    this.temperatureIntervalId !== -1
                      ? (isVoltage ? (diyTemperature / 1000).toFixed(2) : diyTemperature)
                      : (isVoltage ? (currentDiyFirstTemperature/ 1000).toFixed(2) : currentDiyFirstTemperature)
                  }} {{isVoltage ? "V" : "°F"}}
                </span>
                <span
                  v-show="isConnected && isPreheatMode"
                  class="device-temperature"
                >
                  {{ (preheatInfo.temperature / 10.0).toFixed(1) }} W
                </span>
                <span class="device-seconds" v-show="isConnected">
                  {{ seconds.toFixed(1) }}S </span
                >\
                <div class="device-puff" v-show="isConnected">
                  PUFF: {{ currentPuff }}
                </div>
                <div
                  class="device-button"
                  v-show="!isPreheatMode && isConnected && isInsert"
                >
                  <img
                    class="device-button-top"
                    src="@/assets/ui/button_top.png"
                    @touchstart="plusTemperatureStart('+')"
                    @touchend="plusTemperatureEnd('+')"
                    @touchcancel="plusTemperatureEnd('+')"
                  />
                  <img
                    class="device-button-bottom"
                    src="@/assets/ui/button_bottom.png"
                    @touchstart="plusTemperatureStart('-')"
                    @touchend="plusTemperatureEnd('-')"
                    @touchcancel="plusTemperatureEnd('-')"
                  />
                </div>
              </div>
            </div>
            <!-- Device Stats -->
            <div class="col col-6">
              <div class="device-info">
                <div class="device-info-title" @click="onClickDeviceName">
                  {{ getDeviceName }}
                </div>

                <div class="device-item">
                  <span class="device-item-title">BLUETOOTH</span>
                  <span
                    class="device-item-bluetooth text-uppercase"
                    :class="[isConnected ? `connected` : `disconnected`]"
                  >
                    {{ connectState }}</span
                  >
                  <img
                    @click="disconnectBluetooth"
                    class="device-item-img"
                    :src="bluetoothConnectionIcon"
                  />
                </div>
                <div class="device-item">
                  <span class="device-item-title"
                    >BATTERY <span>{{ batteryStr }}</span></span
                  >
                  <div class="device-battery" v-show="isConnected">
                    <img
                      v-for="item of parseInt(battery / 10)"
                      :key="item + '-battery'"
                      class="device-battery-img"
                      src="@/assets/ui/grid_green.png"
                    />
                    <img
                      v-for="item of 10 - parseInt(battery / 10)"
                      :key="item + '-battery-use'"
                      class="device-battery-img"
                      src="@/assets/ui/grid_black.png"
                    />
                  </div>
                </div>
                <div class="device-item">
                  <span class="device-item-title"
                    >COIL LIFE <span> {{ coilLifeStr }}</span></span
                  >
                  <div class="device-battery" v-show="isConnected">
                    <img
                      v-for="item of parseInt(coilLife / 10)"
                      :key="item"
                      class="device-battery-img"
                      src="@/assets/ui/grid_green.png"
                    />
                    <img
                      v-for="item of 10 - parseInt(coilLife / 10)"
                      :key="item + `c`"
                      class="device-battery-img"
                      src="@/assets/ui/grid_black.png"
                    />
                  </div>
                </div>
                <div class="device-item">
                  <span class="device-item-title">DAILY HITS</span>
                  <span class="device-item-msg">{{
                    isInsert && isConnected ? todayPuff : `N/A`
                  }}</span>
                </div>
                <div class="device-item">
                  <span class="device-item-title">TOTAL HITS - AVG SEC</span>
                  <span class="device-item-msg">{{
                    isConnected && isInsert ? totalHitAvg : `N/A`
                  }}</span>
                </div>
                <div class="device-item">
                  <span class="device-item-title">ATTACHMENT</span>
                  <span class="device-item-msg text-uppercase">{{
                    isConnected && isInsert
                      ? isPaste
                        ? `X - BOWL`
                        :  (isVoltage ? 'X - NIC' : 'X - TANK Presets')
                      : `N/A`
                  }}</span>
                  <img class="device-item-img" :src="attachmentIcon" />
                </div>
                <div class="device-item">
                  <div
                    class="device-item-button-left"
                    :class="[
                      !isPreheatMode ? ' device-item-button-action' : '',
                      isConnected ? `connected` : `disconnected`,
                    ]"
                    @click="onClickReady"
                  >
                    READY
                  </div>
                  <div
                    class="device-item-button-right"
                    :class="isPreheatMode ? ' device-item-button-action' : ''"
                    @click="onClickPreheat"
                  >
                    PREHEAT
                  </div>
                </div>
                <!-- <div style="margin-top: 3vh">
                  <img
                    style="height: 3.5em"
                    src="@/assets/logos/one_logo_black.png"
                  />
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <!-- Lower -->
        <div style="height: 5vh">
          <div class="row text-center">
            <div class="col-12 mb-3">
              <div
                style="border-radius: 30px; border: 2px solid #ffab00"
                :style="
                  (presetName !== 'PRESETS' &&
                  !this.isPreheatMode &&
                  isConnected
                    ? 'background:#ffab00;'
                    : '') +
                  (isConnected && !isInsert ? 'border: 2px solid #959595;' : '')
                "
              >
                <p
                  v-show="isConnected && isInsert ? !isPreheatMode : false"
                  class="text-center text-white text-uppercase"
                  style="
                    font-size: 1.3em;
                    font-weight: bold;
                    margin: 6px 0px !important;
                  "
                  @click="onClickPresets"
                >
                  {{ presetName }}
                </p>
                <p
                  v-show="isConnected && isPreheatMode"
                  class="text-center text-white text-uppercase"
                  style="
                    font-size: 1.3em;
                    font-weight: bold;
                    margin: 6px 0px !important;
                  "
                  @click="onClickNormal"
                >
                  NORMAL
                </p>
                <p
                  v-show="!isConnected"
                  class="text-center text-white text-uppercase"
                  style="
                    font-size: 1.3em;
                    font-weight: bold;
                    margin: 6px 0px !important;
                  "
                  @click="onSearchDevice"
                >
                  PAIR DEVICE
                </p>
                <p
                  v-show="isConnected && !isInsert"
                  class="text-center text-white text-uppercase"
                  style="
                    font-size: 1.3em;
                    font-weight: bold;
                    margin: 6px 0px !important;
                    color: #959595 !important;
                  "
                >
                  Connect Attachment
                </p>
              </div>
            </div>
          </div>
        </div>
        <div v-show="false">
          {{ isFire || isPreheat }}
        </div>
        <div v-show="isConnected && isInsert" style="height: 5vh"></div>
        <UpdateDevice
          :show="updateDeviceDialog"
          :device="currentDevice"
          @updialog-close="closeUpdateDeviceDialog"
          @updialog-confirm="confirmUpdateDeviceDialog"
        />
        <Footer />
      </v-container>
    </template>
    <template v-else> Loading...</template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import bluetoothRepository from "@/bluetooth/BluetoothRepository.js";
import log from "../util/log/";
import StoreType from "../store/bluetooth/StoreType";
import { Toast } from "vant";
import { PREHEAT_TYPE_ENTER_START } from "../bluetooth/BluetoothRepository";

export default {
  name: "device_landing",

  data() {
    return {
      loading: {
        overall: true,
      },
      response: {},
      payload: {},
      temperature: "550", //温度
      temperatureW: "10.0", //瓦数
      seconds: 0, //秒数
      puff: "0000", //puff
      daily: "00000000", //DAILY HITS - AVG SEC
      total: "00000000", //TOTAL HITS - AVG SEC
      attachmeny: "X - BOWL",
      deviceStates: "READY",
      secondInteval: -1,
      fireImage: {
        connected: require("@/assets/ui/device_logo_connected.png"),
        disconnected: require("@/assets/ui/device_logo.png"),
      },
      electricityIcon: {
        e4: require("@/assets/ui/electricity-4.png"),
        e3: require("@/assets/ui/electricity-3.png"),
        e2: require("@/assets/ui/electricity-2.png"),
        e1: require("@/assets/ui/electricity-1.png"),
        e0: require("@/assets/ui/electricity-0.png"),
      },
      currentDevice: null,
      updateDeviceDialog: false,
      diyTemperature: 0,
      temperatureIntervalId: -1,
      checkClickTimeoutId: -1,
      longClickIsRun: false,
      temperatureClickId: -1,
    };
  },

  created() {
    this.setup();
  },
  mounted() {
    history.pushState(null, null, document.URL);
    window.addEventListener("popstate", function () {
      history.pushState(null, null, document.URL);
    });
  },

  methods: {
    ...mapActions(["_setDeviceName"]),
    /* API Calls */
    // GET
    // POST
    /* Main */
    async setup() {
      await this.setLoadingState(true);
      // Methods
      await this.setLoadingState(false);

      bluetoothRepository.syncSmokeData();
    },

    /* Misc */
    async setLoadingState(loadingState) {
      this.loading.overall = loadingState;
    },

    async onClickTop() {
      if (!this.isConnected) {
        return;
      }
      if (this.isPreheatMode) return;

      if (this.isFire || this.isPreheat) {
        return;
      }

      if (this.temperatureClickId === -1) {
        this.seconds = 0;
        var temperature = 0
        if (this.isVoltage) {
          temperature = this.currentDiyFirstTemperature + 50;
          if (temperature > 4200) {
            temperature = 2000;
          }
        }else {
          temperature = this.currentDiyFirstTemperature + 5;
          if (temperature > 999) {
            temperature = 200;
          }
        }
        await this.$store.dispatch("keyDownChangeDiyTemperature", {
          temperature,
        });
        this.temperatureClickId = setTimeout(() => {
          this.temperatureClickId = -1;
        }, 180);
      }
    },

    async onClickBottom() {
      if (!this.isConnected) {
        return;
      }
      if (this.isPreheatMode) return;

      if (this.isFire || this.isPreheat) {
        return;
      }

      if (this.temperatureClickId === -1) {
        this.seconds = 0;
        var temperature = 0
        if (this.isVoltage) {
          temperature = this.currentDiyFirstTemperature - 50;
          if (temperature < 2000) {
            temperature = 4200;
          }
        }else {
          temperature = this.currentDiyFirstTemperature - 5;
          if (temperature < 200) {
            temperature = 999;
          }
        }

        await this.$store.dispatch("keyDownChangeDiyTemperature", {
          temperature,
        });

        this.temperatureClickId = setTimeout(() => {
          this.temperatureClickId = -1;
        }, 180);
      }
    },

    plusTemperatureStart(type) {
      clearTimeout(this.checkClickTimeoutId);
      if (!this.isConnected) {
        return;
      }
      if (this.isPreheatMode) return;

      if (this.isFire || this.isPreheat) {
        return;
      }

      this.checkClickTimeoutId = setTimeout(() => {
        this.longClickIsRun = true;
        this.seconds = 0;
        this.diyTemperature = this.currentDiyFirstTemperature;
        clearInterval(this.temperatureIntervalId);
        this.temperatureIntervalId = setInterval(() => {
          if (type === "+") {
            if (this.isVoltage) {
              this.diyTemperature = this.diyTemperature + 50;
              if (this.diyTemperature > 4200) {
                this.diyTemperature = 2000;
              }
            }else {
              this.diyTemperature = this.diyTemperature + 5;

              if (this.diyTemperature > 999) {
                this.diyTemperature = 200;
              }
            }

          } else {
            if (this.isVoltage) {
              this.diyTemperature = this.diyTemperature - 50;
              if (this.diyTemperature < 2000) {
                this.diyTemperature = 4200;
              }
            }else {
              this.diyTemperature = this.diyTemperature - 5;
              if (this.diyTemperature < 200) {
                this.diyTemperature = 999;
              }
            }
          }
        }, 100);
      }, 300);
    },
    plusTemperatureEnd(type) {
      clearTimeout(this.checkClickTimeoutId);
      if (!this.isConnected) {
        return;
      }
      if (this.isPreheatMode) return;

      if (this.isFire || this.isPreheat) {
        return;
      }
      clearInterval(this.temperatureIntervalId);
      if (this.longClickIsRun) {
        this.longClickIsRun = false;
        this.temperatureIntervalId = -1;
        this.$store.dispatch("keyDownChangeDiyTemperature", {
          temperature: this.diyTemperature,
        });
      } else {
        if (type === "+") {
          this.onClickTop();
        } else {
          this.onClickBottom();
        }
      }
    },

    onClickReady() {
      if (!this.isConnected) {
        return;
      }
      if (this.isFire || this.isPreheat) {
        return;
      }
      this.deviceStates = "READY";
      bluetoothRepository.changeOutputMode(
        StoreType.FIRE_MODE_TYPE.FIRE_MODE_TEMPERATURE
      );
    },
    onClickPreheat() {
      if (!this.isConnected) {
        this.$showTips({
          msg: "Please Connect Device",
          duration: 3000,
        });
        // setTimeout(() => {
        //   this.$router.replace({ name: "pair_main" });
        // }, 3200);
        // Toast.fail({
        //   message: "Please connect device.",
        //   icon: "../assets/icons/exclamation.png",
        //   duration: 0,
        // });
        return;
      }
      if (!this.isInsert) {
        // Toast.fail("Please Insert Cartridge.");
        this.$showTips({
          msg: "Please Insert Cartridge",
        });
        return;
      }

      if (this.isFire || this.isPreheat) {
        return;
      }
      this.deviceStates = "PREHEAT";
      this.seconds = this.preheatSecond;
      bluetoothRepository.changeOutputMode(
        StoreType.FIRE_MODE_TYPE.FIRE_MODE_PREHEAT
      );
    },
    onClickPresets() {
      this.$router.push({
        name: "presets",
      });
    },
    onClickNormal() {
      bluetoothRepository
        .writePreheat(PREHEAT_TYPE_ENTER_START, 55, 15)
        .then(() => {
          this.togglePreheat();
        });
    },
    onSearchDevice() {
      this.$router.replace({ name: "pair_main" });
    },
    togglePreheat(e) {
      if (this.isPreheatMode) {
        if (this.isPreheat) {
          clearInterval(bluetoothRepository.secondTimeoutId);
          this.seconds = this.preheatInfo.time;
          bluetoothRepository.stopPreheat();
        } else {
          bluetoothRepository.startPreheat();
        }
      }
    },
    async toggleFireStart(e) {
      if (!this.isConnected) {
        this.$showTips({
          msg: "Please Connect Device",
          duration: 3000,
        });
        // setTimeout(() => {
        //   this.$router.replace({ name: "pair_main" });
        // }, 3200);
        // Toast.fail({
        //   message: "Please connect device.",
        // });
        // this.$router.replace({ name: "pair_main" });
        return;
      }
      if (!this.isInsert) {
        return;
      }
      if (!this.isPreheatMode) {
        e.preventDefault();
        log("start fire");
        await bluetoothRepository.startFire();
      }
    },
    async toggleFireStop(e) {
      if (!this.isPreheatMode) {
        e.preventDefault();
        log("stop fire");

        if (!this.isConnected) {
          // this.$router.replace({ name: "pair_main" });
          return;
        }

        if (!this.isInsert) {
          return;
        }
        await new Promise((resolve) => setTimeout(resolve, 100));
        await bluetoothRepository.stopFire();
      }
    },
    disconnectBluetooth() {
      if (this.isConnected) {
        bluetoothRepository.disconnect();
      } else {
        this.$router.replace({ name: "pair_main" });
      }
    },
    onClickDeviceName() {
      this.currentDevice = {
        deviceNumber: this.$store.state.bluetooth.macAddress,
        deviceName: this.getDeviceName,
      };
      this.updateDeviceDialog = true;
    },
    closeUpdateDeviceDialog(data) {
      this.updateDeviceDialog = data;
    },
    confirmUpdateDeviceDialog(data) {
      this._setDeviceName(data);
    },
  },

  watch: {
    isInsert(newValue, oldValue) {
      if (!newValue) {
        this.seconds = 0;
      }
    },
    isPreheatMode(newValue, oldValue) {
      if (newValue) {
        this.seconds = this.preheatInfo.time;
      } else {
        if (!this.isPreheat) {
          this.seconds = 0;
        }
      }
    },
    isFire(newValue, oldValue) {
      if (newValue) {
        clearInterval(bluetoothRepository.secondTimeoutId);
        this.seconds = 0;
        bluetoothRepository.secondTimeoutId = setInterval(() => {
          this.seconds += 0.1;
        }, 100);
      } else {
        clearInterval(bluetoothRepository.secondTimeoutId);
      }
    },
    isPreheat(newValue, oldValue) {
      if (newValue) {
        clearInterval(bluetoothRepository.secondTimeoutId);
        this.seconds = this.$store.state.bluetooth.preheat.time;
        bluetoothRepository.secondTimeoutId = setInterval(() => {
          this.seconds -= 0.1;
        }, 100);
      } else {
        clearInterval(bluetoothRepository.secondTimeoutId);
      }
    },
    preheatInfo(newValue, oldValue) {
      if (this.isPreheatMode) {
        this.seconds = newValue.time;
      }
    },
  },

  computed: {
    ...mapGetters([
      "getMainColor",
      "getAccentColor",
      "isConnected",
      "battery",
      "connectState",
      "coilLife",
      "dailyHitsAvg",
      "totalHitAvg",
      "preheatInfo",
      "currentPuff",
      "diyInfo",
      "fireMode",
      "currentDiyFirstTemperature",
      "isPreheat",
      "getDeviceName",
      "getPresetInfo",
      "isInsert",
      "isFire",
      "selectedPresetItem",
      "todaySeconds",
      "todayPuff",
      "presetName",
      "isPaste",
      "isVoltage"
    ]),

    isPreheatMode() {
      return this.fireMode === StoreType.FIRE_MODE_TYPE.FIRE_MODE_PREHEAT;
    },
    temperatureNow() {
      if (this.getPresetInfo != null) return this.getPresetInfo.value;
      else return this.temperature;
    },

    batteryStr() {
      if (!this.isConnected) {
        return " ( - - ) %";
      }
      return ` ${this.battery} %`;
    },

    batteryIcon() {
      const battery = this.battery;
      let batteryIcon = this.electricityIcon.e4;
      if (battery <= 40) {
        batteryIcon = this.electricityIcon.e1;
      } else if (battery <= 55) {
        batteryIcon = this.electricityIcon.e2;
      } else if (battery <= 70) {
        batteryIcon = this.electricityIcon.e3;
      } else if (battery === 0) {
        batteryIcon = this.electricityIcon.e0;
      }
      return batteryIcon;
    },
    bluetoothConnectionIcon() {
      return this.isConnected
        ? require("@/assets/ui/bluetooth.png")
        : require("@/assets/ui/bluetooth_disconnect.png");
    },
    attachmentIcon() {
      return this.isConnected
        ? require("@/assets/ui/attachment.png")
        : require("@/assets/ui/attachment_disconnect.png");
    },
    coilLifeStr() {
      if (!this.isConnected) {
        return " ( - - ) %";
      }

      return ` ${this.coilLife} %`;
    },
  },
};
</script>

<style lang="less" scoped>
.device {
  position: relative;
  display: flex;
  justify-content: center;

  .device-background {
    height: 56vh;
    width: 8em;
    position: absolute;
    z-index: 1000;
  }

  .device-logo {
    width: 4em;
    height: 4em;
    margin-top: 12vh;
    position: absolute;
    z-index: 1100;
    -webkit-touch-callout: none;
  }

  .device-title {
    margin-top: 22vh;
    z-index: 1100;

    .device-title-logo {
      width: 40px;
      margin-left: 10px;
    }

    .device-title-electricity {
      width: 22px;
      margin-left: 12px;
    }
  }

  .device-temperature {
    z-index: 1100;
    position: absolute;
    margin-top: 25vh;
    color: white;
    font-size: 1.5em;
    font-weight: bold;
  }

  .device-seconds {
    z-index: 1100;
    position: absolute;
    margin-top: 29vh;
    color: white;
    font-size: 1.3em;
    font-weight: bold;
  }

  .device-puff {
    z-index: 1100;
    position: absolute;
    margin-top: 33.5vh;
    color: white;
    font-size: 10px;
    border: 1px solid white;
    border-radius: 10px;
    padding: 0 6px;
  }

  .device-button {
    z-index: 1100;
    position: absolute;
    margin-top: 41vh;
    display: flex;
    flex-direction: column;

    .device-button-top {
      width: 5em;
    }

    .device-button-bottom {
      width: 5em;
      margin-top: 3px;
    }
  }
}

.device-info {
  .device-info-title {
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 4.7vh;
    line-height: 5vh;
    word-wrap: break-word;
    margin-bottom: 3vh;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .device-item {
    background-color: #000000;
    width: 100%;
    height: 5vh;
    border-radius: 5vh;
    margin-top: 1.4vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .device-item-title {
      color: white;
      font-size: 1.5vh;
      position: absolute;
      top: -1.2vh;
      font-weight: bold;

      span {
        color: #9aff92;
      }
    }

    .device-item-bluetooth {
      font-size: 1.5vh;
      font-weight: bold;
    }

    .connected {
      color: #63bbff;
    }

    .disconnected {
      color: #333333;
    }

    .device-item-img {
      width: 3.5vh;
      height: 3.5vh;
      position: absolute;
      right: 0.5vh;
    }

    .device-battery {
      display: flex;

      .device-battery-img {
        width: 1.2vh;
        height: 2.5vh;
        margin-right: 3px;
      }
    }

    .device-item-msg {
      color: white;
      font-size: 1.5vh;
      font-weight: bold;
    }

    .device-item-button-left {
      height: 5vh;
      line-height: 5vh;
      color: #959595;
      font-size: 1.5vh;
      font-weight: bold;
      width: 50%;
      text-align: center;
    }

    .device-item-button-right {
      height: 5vh;
      line-height: 5vh;
      color: #959595;
      font-size: 1.5vh;
      font-weight: bold;
      width: 50%;
      text-align: center;
    }

    .device-item-button-action {
      background: #ffab00 !important;
      color: white !important;
      border-radius: 5vh !important;

      &.connected {
      }

      &.disconnected {
        background: #999999 !important;
      }
    }
  }
}
</style>
